import React from 'react'
import styled from 'styled-components'

import ImageCard from '../UI/Card/ImageCard'
import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'

const MediaReleases = () => {
  const renderHomeButton = () => (
    <HomeButton
      height={BUTTON_SIZE.HEIGHT_S}
      padding={BUTTON_SIZE.PADDING_S}
      fontSize={BUTTON_SIZE.FONT_SIZE_S}
      background={BUTTON_COLOR.GREEN}
      backgroundHover={BUTTON_COLOR.GREEN_HOVER}
      color={BUTTON_COLOR.TEXT_COLOR_WHITE}
      margin="0"
      text="Download"
      handleClick={() => {}}
    />
  )

  return (
    <MediaReleasesWrapper>
      <ImageCard width="100%" border="1px solid #d4dbe0">
        <div className="media-info">
          <h1 className="media-day greenText">13</h1>
          <h4 className="media-date">
            Apr
            <br />
            2021
          </h4>
        </div>
        <div className="vertical-line"></div>
        <h3>
          SocietyOne Appoints Melanie Cochrane &amp; Jamie McPhee As Independent
          Non-Executive Directors
        </h3>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://assets-global.website-files.com/5c5cd8d89975d57c5ec4a23f/6074f3ccf497c9f8aaf930f6_SocietyOne%20appoints%20Melanie%20Cochrane%20%26%20Jamie%20McPhee%20as%20Independent%20Non-Executive%20Directors.pdf"
        >
          {renderHomeButton()}
        </a>
      </ImageCard>
      <ImageCard width="100%" border="1px solid #d4dbe0">
        <div className="media-info">
          <h1 className="media-day greenText">23</h1>
          <h4 className="media-date">
            Feb
            <br />
            2021
          </h4>
        </div>
        <div className="vertical-line"></div>
        <h3>
          Westpac And SocietyOne Announce New Partnership For Digital Banking
        </h3>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://assets-global.website-files.com/5c5cd8d89975d57c5ec4a23f/6034541c8a6c82399b4ca0a9_230221%20Westpac%20and%20SocietyOne%20partnership.pdf"
        >
          {renderHomeButton()}
        </a>
      </ImageCard>
      <ImageCard width="100%" border="1px solid #d4dbe0">
        <div className="media-info">
          <h1 className="media-day greenText">3</h1>
          <h4 className="media-date">
            Feb
            <br />
            2021
          </h4>
        </div>
        <div className="vertical-line"></div>
        <h3>SocietyOne Receives Very Strong Rating On Personal Loans Trust</h3>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://assets-global.website-files.com/5c5cd8d89975d57c5ec4a23f/60454c195bba09fdf3e8210d_SocietyOne%20receives%20very%20strong%20rating%20on%20Personal%20Loans%20Trust.pdf"
        >
          {renderHomeButton()}
        </a>
      </ImageCard>
    </MediaReleasesWrapper>
  )
}

const MediaReleasesWrapper = styled.div`
  margin-top: 60px;
  h3 {
    line-height: 1.3;
  }
  >div {
    margin: 0 0 24px 0;
  }
  .desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      margin: 0;
    }
    height: 100%;
  }
  .vertical-line {
    width: 1px;
    height: 100px;
    margin-right: 48px;
    margin-left: 48px;
    background-color: #d4dbe0;
  }
  .media-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-width: 130px;
    .media-date {
      font-family: Poppins;
    }
  }
  .greenText {
    color: var(--base-green);
  }
  @media screen and (max-width: 760px) {
    h3 {
      font-size: 20px;
    }
    .desc {
      align-items: start;
      flex-direction: column;
      button {
        margin-top: 20px;
      }
    }
    .media-date {
      font-size: 16px;
    }
    .vertical-line {
      margin: 5px 0 12px;
      height: 1px;
      width: 100%;
      border-top: 1px solid #d4dbe0;
    }
  }
`

export default MediaReleases
