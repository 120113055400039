import React, { useState } from 'react'
import styled from 'styled-components'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabPanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabPanel-${index}`,
  }
}

const TwoTabs = ({
  firstTab,
  firstLabel,
  secondTab,
  secondLabel,
  tabsStylesProp,
  tabStylesProp,
}) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <TabsWrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          '& .MuiTabs-flexContainer': {
            justifyContent: tabsStylesProp.justifyContent || 'center',
          },
          '& .MuiTabs-indicator': { height: tabsStylesProp.height || '1px' },
        }}
      >
        <Tab label={firstLabel} {...a11yProps(0)} sx={{fontSize: tabStylesProp.fontSize}} />
        <Tab label={secondLabel} {...a11yProps(1)} sx={{fontSize: tabStylesProp.fontSize}} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {firstTab}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {secondTab}
      </TabPanel>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div``

export default TwoTabs
