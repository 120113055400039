import React from 'react'
import styled from 'styled-components'

import ImageCard from '../UI/Card/ImageCard'
import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import { StaticImage } from 'gatsby-plugin-image'

const AsSeenInArticle = () => {
  const renderHomeButton = () => (
    <HomeButton
      height={BUTTON_SIZE.HEIGHT_S}
      padding={BUTTON_SIZE.PADDING_S}
      fontSize={BUTTON_SIZE.FONT_SIZE_S}
      background={BUTTON_COLOR.GREEN}
      backgroundHover={BUTTON_COLOR.GREEN_HOVER}
      color={BUTTON_COLOR.TEXT_COLOR_WHITE}
      margin="0"
      text="View Article"
      handleClick={() => {}}
    />
  )

  return (
    <AsSeenInWrapper>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.brokernews.com.au/news/breaking-news/societyone-launches-secured-loan-product-275274.aspx"
      >
        <ImageCard
          width="313px"
          border="1px solid #d4dbe0"
          cardStyleOnHover="transform: translate(0, -20px);
                              transition: 0.5s;"
        >
          <h3>Marketplace Lender SocietyOne Tops $1 Billion in Lending</h3>
          <div className="pr-icon">
            <StaticImage
              src="../../assets/images/AustralianBroker.png"
              alt="AustralianBroker-image"
              placeholder='none'
            />
          </div>
          <div className="pr-date">Feb 3, 2021</div>
          {renderHomeButton()}
        </ImageCard>
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.crowdfundinsider.com/2021/02/171838-marketplace-lender-societyone-tops-1-billion-in-lending/"
      >
        <ImageCard
          width="313px"
          border="1px solid #d4dbe0"
          cardStyleOnHover="transform: translate(0, -20px);
                              transition: 0.5s;"
        >
          <h3>SocietyOne launches secured loan product</h3>
          <div className="pr-icon">
            <StaticImage
              src="../../assets/images/CrowdFundInsider.jpeg"
              alt="CrowdFundInsider-image"
              placeholder='none'
            />
          </div>
          <div className="pr-date">Feb 2, 2021</div>
          {renderHomeButton()}
        </ImageCard>
      </a>
    </AsSeenInWrapper>
  )
}

const AsSeenInWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  a {
    display: flex;
  }
  @media screen and (max-width: 760px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export default AsSeenInArticle
