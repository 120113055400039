import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import TwoTabs from '../UI/TwoTabs'
import AsSeenInArticle from './AsSeenInArticle'
import MediaReleases from './MediaReleases'
import { CONTACT } from '../../support'

const MediaCentre = () => {
  const tabsStylesProp = {
    height: '4px',
    justifyContent: 'start',
  }

  const tabStylesProp = {
    fontSize: '20px',
  }

  return (
    <ContentContainer maxWidth="1020px">
      <MediaCentreWrapper id="media-centre">
        <h2 className="text-center">Media Centre</h2>
        <p className="text-center">
          For all public relations and media enquiries, contact:{' '}
          <a href={`mailto:${CONTACT.EMAIL.MEDIA}`}>
            <strong className="quicksand">marketing@societyone.com.au</strong>
          </a>
        </p>
        <TwoTabs
          firstTab={<AsSeenInArticle />}
          firstLabel="As Seen In"
          secondTab={<MediaReleases />}
          secondLabel="Media Releases"
          tabsStylesProp={tabsStylesProp}
          tabStylesProp={tabStylesProp}
        />
      </MediaCentreWrapper>
    </ContentContainer>
  )
}

const MediaCentreWrapper = styled.div`
  margin-top: -120px;
  padding-top: 120px;
  h3 {
    line-height: 1.5;
    font-size: 28px;
  }
  p.text-center {
    margin-bottom: 80px;
  }
  a {
    &:hover {
      color: inherit;
    }
  }
  .desc {
    margin: -12px;
  }
  .pr-icon {
    width: 80%;
    max-width: 180px;
    margin-top: 40px;
  }
  .pr-date {
    font-size: 14px;
    margin: 8px 0 32px;
  }
`

export default MediaCentre
