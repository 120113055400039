import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { parseJson } from '../../support'
import Layout from '../../layouts/layout'
import Seo from '../../components/seo'
import WeAreSocietyOne from '../../components/UI/HeaderAndImageOnBottom'
import MediaCentre from '../../components/ForAboutUs/MediaCentre'

const Media = ({ path }) => {
  const queriedStructuredDataConfigForMedia = useStaticQuery(graphql`
    query structuredDataConfigForMediaQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "about-us/media" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForMedia.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  return (
    <Layout>
      <Seo 
        title="Media Inquiries & Media Releases"
        description="Keep up to date with all of the latest SocietyOne media releases, news pieces, statements and publications." 
        path={path}
        structuredData={structuredData}
      />
      <WeAreSocietyOne slugQuery="/about-us/media" />
      <MediaCentre />
    </Layout>
  )
}

export default Media